import styled from "styled-components";

export const CategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap:  2px;
  row-gap: 50px;
    @media (max-width: 950px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 760px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 530px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const CategoryTitle = styled.h2`
  text-align: center;
  font-size: 38px;
  margin-bottom: 25px;
`;

import styled from "styled-components";


export const SignUpContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 380px;
`;

export const Header2 = styled.h2`
    margin: 10px 0;
`;

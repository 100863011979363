import styled from "styled-components";
import { Link } from "react-router-dom"

export const CategoryPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;
export const CategoryTitle = styled(Link)`
  font-size: 28px;
  margin-bottom: 25px;
  cursor: pointer;
`;
export const CategoryPreviewStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
    @media (max-width: 950px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 760px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 530px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
